// src/components/StepOne.js
import React, { useEffect, useState } from 'react';
import './StepOne.css'

const StepOne = ({ formData, handleChange, nextStep }) => {

  const [showSelect, setshowSelect] = useState(false);

  function changeSelectSelection() {
    setshowSelect(true);
  }
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  });

  return (
    <div className='form-partone-container'>
      <div className='form-partone-container-header-div'>
        <p className='form-partone-container-header'>Welcome to NebourHood!</p>
        <p className='form-partone-container-subheader'>
          Please take a few minutes and kindly fill in all the details correctly to get in touch with a suitable Service Professional (Interior Designer / Architect / Engineer / Contractor).
        </p>
      </div>
      <div className='form-partone-container-whole-div'>
        <div className='servicetype-dropdown'>
          <p className='serviceType-header'>Your Building Type and Service Required <span style={{ color: 'red' }}>*</span></p>
          <select className='ServiceType-select' name='ServiceType' value={formData.ServiceType} onChange={handleChange} onClick={changeSelectSelection}>
            <option value="" disabled={showSelect}>Select</option>
            <option value="Residential Interiors">Residential Interiors</option>
            <option value="Commercial Interiors">Commercial Interiors</option>
            <option value="Residential Construction">Residential Construction</option>
            <option value="Commercial Construction">Commercial Construction</option>
          </select>
        </div>
        <div className='serviceType-button-container'>
          {!formData.ServiceType.trim() ?
          <button className='serviceType-button-disable' onClick={nextStep} disabled={!formData.ServiceType.trim()}>Next</button>
          :
          <button className='serviceType-button-enabled' onClick={nextStep} disabled={!formData.ServiceType.trim()}>Next</button>
          }
        </div>
      </div>
      </div>
  );
};

export default StepOne;
