export const PageViewTag = () => {
    if(window.fbq){
        window.fbq('track', 'PageView');
    }
}
export const AddPaymentInfoTag = () => {
    if(window.fbq){
        window.fbq('track', 'AddPaymentInfo');
    }
}
export const AddToCartTag = () => {
    if(window.fbq){
        window.fbq('track', 'AddToCart');
    }
}
export const CompleteRegistrationTag = () => {
    if(window.fbq){
        window.fbq('track', 'CompleteRegistration');
    }
}
export const ContactTag = () => {
    if(window.fbq){
        window.fbq('track', 'Contact');
    }
}
export const LeadTag = () => {
    if(window.fbq){
        window.fbq('track', 'Lead');
    }
}
export const ThankyouPage = () => {
    if(window.fbq){
        window.fbq('track', 'ThankyouPage');
    }
}
export const POLandingPage = () => {
    if(window.fbq){
        window.fbq('track', 'POLandingPage');
    }
}
export const POLeadReceived = () => {
    if(window.fbq){
        window.fbq('track', 'POLeadReceived');
    }
}