import React, { useEffect, useLayoutEffect, useState } from "react";
import "./MyPurchasedLeadTable.css";
import { table } from "./Data";
// import file from "./file.docx";
import { useDispatch, useSelector } from "react-redux";
import LoadingIcon from "../../../utils/LoadingIcon/LoadingIcon";
// import DownloadLead from "./DownloadLead";
import { withRouter } from "react-router-dom";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import LeadsInvoice from "../LeadsInvoice/LeadsInvoice";
import { DownloadLeadPDF, getEmailNotificationforLead, replacementLead } from "../../../../_actions/company_actions";

function MyPurchasedLeadTable() {
  const company = useSelector((state) => state.company);
  const dispatch = useDispatch();

  const bodywidth = document.querySelector("body").scrollWidth;
  const [emailButton, setemailButton] = useState(false);


  const [screenwidth, setscreenwidth] = useState(bodywidth);

  useLayoutEffect(() => {
    window.addEventListener("resize", () => {
      setscreenwidth(window.innerWidth);
    });
  }, [screenwidth]);

  const [invoicedetail, setinvoicedetail] = useState({
    UserDetails: {
      EmailId: "",
      PhoneNumber: 0,
      ProfileNumber: ""
    },
    PersonalDetails: {
      PropertyOwnerName: "",
      AlternativeEmailId: "",
      AlternativePhoneNumber: 0,
      PersonalWhatsappNumber: 0,
    },
    Address: {
      HouseName: "",
      State: "",
      City: "",
      AreaName: "",
      Pincode: "",
    },
    ServiceTypeRequired: {
      ServiceType: "",
      PropertyType: "",
      PropertyStatus: "",
      ServiceRequirement: "",
    },
    TimeFrame: "",
    PropertyInformation: {
      PropertyRequirementNumber: "",
      PropertyDimensionType: "",
      PropertyArea: "",
      PropertyCondition: "",
    },
    Budget: "",
    PropertyRequirements: "",
    OrderID: 0
  });

  const [changed, setchanged] = useState(false)

  const DownloadLeadDetails = async (ProductId, UserID, OrderID) => {
    // console.log("Downlaod Leads", ProductId);
    let variables = {
      LeadId: ProductId,
      UserID: UserID,
    };
    dispatch(DownloadLeadPDF(variables)).then((response) => {

      const result = response.payload.result
      // console.log(result)
      setinvoicedetail({

        UserDetails: {
          EmailId: result.userDetails.email,
          PhoneNumber: result.userDetails.phonenumber,
          ProfileNumber: result.userDetails._id
        },
        PersonalDetails: {
          PropertyOwnerName: result.userDetails.name,
          AlternativeEmailId: result.userDetails.Personal_Details.Alternative_Email_Id,
          AlternativePhoneNumber: result.userDetails.Personal_Details.Alternative_Phone_Number,
          PersonalWhatsappNumber: result.userDetails.Personal_Details.Personal_WhatsApp_Number,
        },
        Address: {
          HouseName: result.productDetails.Land_Building,
          State: result.productDetails.State,
          City: result.productDetails.City,
          AreaName: result.productDetails.Area_Name,
          Pincode: result.productDetails.Pin_Code,
        },
        ServiceTypeRequired: {
          ServiceType: result.productDetails.Project_Type,
          PropertyType: result.productDetails.BuildingType,
          PropertyStatus: result.productDetails.Status_Of_Project,
          ServiceRequirement: result.productDetails.Service_Type_Required,
        },
        TimeFrame: result.productDetails.Beginning_The_Project,
        PropertyInformation: {
          PropertyRequirementNumber: result.productDetails._id,
          PropertyDimensionType: result.productDetails.Dimension_Type,
          PropertyArea: result.productDetails.Project_Area,
          PropertyCondition: result.productDetails.Property_Condition,
        },
        Budget: result.productDetails.Budget,
        PropertyRequirements: result.productDetails.description,
        OrderID: OrderID
      },)
      setchanged(true)
    }

    )
  }

  useEffect(() => {
    if (changed) {
      handledownloadpdf()
      setchanged(false)
    }
  }, [changed])

  const handledownloadpdf = () => {

    // setdata(e).then(() => {
    // console.log("set");
    const invoicecontainer = document.getElementById(
      "leads-invoice-container"
    );
    invoicecontainer.style.display = "block";
    html2canvas(document.getElementById("leads-invoice-container"), {
      useCORS: true,
      scale: 1,
    }).then((canvas) => {
      const pdf = new jsPDF("l", "pt", [canvas.width, canvas.height]);
      // console.log(canvas.width)
      // console.log(canvas.height)
      var width = pdf.internal.pageSize.getWidth();
      var height = pdf.internal.pageSize.getHeight();
      // console.log(width)
      // console.log(height)
      const imgData = canvas.toDataURL("image/jpeg", 1.0);
      pdf.addImage(imgData, "JPEG", 0, 0, width, height);
      pdf.save("download.pdf");
      invoicecontainer.style.display = "none";
    });
    // });
  };

  const applyForReplacement = (OrderID, leadId, planName) => {
    dispatch(replacementLead(OrderID, leadId, planName, new Date()))
  }
  const sendEmailNotification = (data) => {
    setemailButton(true);
    dispatch(getEmailNotificationforLead(data))
    .then((response) => {
      if(response.payload.success) {
        alert(response.payload.message);
        setemailButton(false);
      } else {
        alert(response.payload.message);
        setemailButton(false);
      }
    })
  }

  function checkForReplacement(value) {
    var date = new Date(value);
    date.setDate(date.getDate() + 120);
    var currentDate = new Date();
    if(currentDate.getTime()<date.getTime()){
      return true;
    } else {
      return false;
    }
  }

  function getTotalSaving(nonprime, prime){
    if(nonprime === "Baby Bear" || nonprime === "Startup Tiger" || nonprime === "Elite Tiger" || nonprime === "Classic Tiger" || nonprime === "Baby Tiger"){
        return nonprime;
    }
    if(prime === "Baby Bear Prime" || prime === "Startup Tiger Prime" || prime === "Elite Tiger Prime" || prime === "Classic Tiger Prime" || prime === "Baby Tiger Prime"){
      return prime;
    }
    return nonprime + prime;
  }

  if (company.companyData && company.companyData.isAuth) {
    // console.log(company.companyData.history);
    return (
      <>
      <div style={{ textAlign: 'center', justifyContent: 'center' }}>
          <p style={{ fontSize: '25px', fontWeight: 'bold' }}>My Leads</p>
        </div>
      <div className="MyPurchasedLeads-container">
        <div className="mypurchasedleads-transaction-table">
          <table>
            <tr className="table-titles">
              {table.titles.map((title, index) => {
                return (
                  <th
                    key={index}
                    colSpan={title.colspan}
                    rowSpan={title.rowspan}
                    style={{color: title.colour}}
                  >
                    {title.Name}
                  </th>
                );
              })}
            </tr>
            <tr className="table-sub-titles">
              {table.subtitles.map((subtitle, index) => {
                return <td key={index}>{subtitle}</td>;
              })}
            </tr>

            {company.companyData.history.map((data, index) => {
              return (
                <tr className="transaction-data">
                  <td>{index + 1}</td>
                  <td id="orderID">{data.paymentId}</td>
                  <td id="orderID">{data.OrderID}</td>
                  <td id="orderID">{data.DateTime}</td>
                  <td id="profilenumber">
                    <a href={`/OtherViewProfile/PropertyOwner/${data.writer}`} style={{ color: '#1DA1F2', textDecoration: 'none' }}>
                      View
                    </a>
                  </td>
                  <td id="name">{data.name}</td>
                  <td id="propertynumber">
                    <a href={`/ServiceProfessional/PropertyRequirementsDetails/${data.id}`} style={{ color: '#1DA1F2', textDecoration: 'none' }}>
                      {/* {data.id} */}
                      View
                    </a>
                  </td>
                  <td id="housename">{data.Land_Building}</td>
                  {/* <td id="state">{data.State}</td> */}
                  <td id="city">{data.City}</td>
                  <td id="projecttype">{data.Project_type} - {data.BuildingType}</td>
                  {/* <td>1</td> */}
                  <td style={{ color: 'red' }}><del>{data.originalAmount}</del></td>
                  <td>{data.amount}</td>
                  <td>{data.gstAmount}</td>
                  <td style={{ color: '#1F80E0' }}>{data.finalAmount}</td>
                  <td style={{ color: 'green' }}>0</td>
                  <td style={{ color: 'green' }}>{data.nonPrimeSavings}</td>
                  <td style={{ color: 'green' }}>{data.primeSavings}</td>
                  <td style={{ color: 'green' }}>
                    {getTotalSaving(data.nonPrimeSavings, data.primeSavings)}
                    {/* {data.nonPrimeSavings ==="Baby Bear" || 
                    data.nonPrimeSavings ==="Baby Tiger" ? 0 : data.nonPrimeSavings + data.primeSavings === "Baby Bear Prime" || data.primeSavings === "Baby Tiger Prime" ? data.primeSavings : 0} */}
                    </td>
                  {/* <td>{data.paymentMode}</td> */}
                  <td>
                    {checkForReplacement(data.DateTime) ? 
                    <>
                      {data.replacement ? 
                      <button className="replacement-btn" 
                      onClick={()=> applyForReplacement(data.OrderID, data.id, data.planName)}
                      >
                      Initiate
                      </button>
                      : 
                      <>
                      {data.replacementStatus === 0 ? <p style={{color: "green"}} >Pending</p> : 
                      data.replacementStatus === 1 ? <p style={{color: "orange"}} >Approved</p> :
                      data.replacementStatus === 2 ? <p style={{color: "blue"}} >Expired</p> : "-"
                    }
                    </>
                  }
                  </>
                  : <p style={{color: "blue"}} >Expired</p> }
                  </td>
                  <td>{!data.replacementInitiation ? '-' : data.replacementInitiation} </td>
                  <td>{!data.replacementApproval ? '-' : data.replacementApproval} </td>
                  <td>
                    <span
                      onClick={() => DownloadLeadDetails(data.id, data.writer, data.OrderID)} className="material-icons"
                    >
                      download
                    </span>
                    {/* <a >Download</a> */}
                  </td>
                  <td><button className="email-buttom-details" onClick={() => sendEmailNotification(data)} disabled={emailButton} style={{ backgroundColor: emailButton ? "#95989C": '#fe5631' }} >Send</button></td>
                </tr>
              );
            })}
          </table>
        </div>
        <LeadsInvoice detailstopass={invoicedetail} SPName={company.companyData.name} />
        {/* <div>
          <MembershipDisclaimer page="MyLeads" /> 
        </div> */}
      </div>
      </>
    );
  } else {
    return <LoadingIcon />;
  }
}
export default withRouter(MyPurchasedLeadTable);
