// src/components/StepFour.js
import React, { useEffect, useState } from 'react';
import './StepFour.css'
import { chatgptAIPrompt } from '../../../../_actions/chatgpt_actions';
import { useDispatch } from 'react-redux';

const StepFour = ({ formData, handleChange, prevStep, nextStep, setFormData }) => {

  const dispatch = useDispatch();

  const [DescriptionInput, setDescriptionInput] = useState('');
  const [debouncedValue, setDebouncedValue] = useState('');

  // useEffect(() => {
    // const timeoutId = setTimeout(() => {
    //   setDebouncedValue(DescriptionInput);
    // }, 3000);
    // return () => {
    //   clearTimeout(timeoutId);
    // };
  // }, [DescriptionInput]);

  // useEffect(() => {
  //   if (debouncedValue) {
  //     handleFunctionCall(debouncedValue);
  //   }
  // }, [debouncedValue]);

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  });

  useEffect(() => {
    // console.log("Form Data ", formData);
    if(formData.PropertyType.trim() && formData.ServiceRequirements.trim() && formData.StartOfTheWork.trim() && formData.ServiceType.trim()){
      setDescriptionInput(formData.DescriptionalPrompt)
      // console.log("Ready to trigger for ChatGPT API...");
      if(!formData.gptAICalled){
        dispatch(chatgptAIPrompt(formData = {
          ServiceType: formData.ServiceType,
          ProjectArea: formData.ProjectArea,
          ResidentailRequirementsString: formData.ResidentailRequirementsString,
          PropertyRequirementsString: formData.PropertyRequirementsString,
          PropertyType: formData.PropertyType,
          ServiceRequirements: formData.ServiceRequirements,
          StartOfTheWork: formData.StartOfTheWork,
        })).then((response) => {
            if(response.payload.success){
                // console.log("ChatGPT API response Success: ", response);
                setDescriptionInput(response.payload.message);
                setFormData((prevData) => ({
                  ...prevData,
                  gptAICalled: true,
                  DescriptionalPrompt: response.payload.message
                }))
            } else {
                // console.log("ChatGPT API response Failed: ", response);
                setDescriptionInput(formData.ServiceType + " " +formData.ProjectArea + " " + formData.ResidentailRequirementsString + " " + formData.PropertyRequirementsString + " " +formData.PropertyType + "" +formData.ServiceRequirements + " " +formData.StartOfTheWork);
                formData.gptAICalled = true;
            }
          })
      }
    } else {
      setDescriptionInput("")
    }
  }, [formData.PropertyType, formData.ServiceRequirements, formData.StartOfTheWork]);

  // const handleFunctionCall = (value) => {
  //   console.log("FormData ", formData);
  //   console.log('Function called with input:', value);
  // };
  
  const handleInputChange = (e) => {
    // console.log(e.target.value);
    setDescriptionInput(e.target.value);
    setFormData((prevData) => ({
      ...prevData,
      DescriptionalPrompt: DescriptionInput
    }))
  };

  const [showSelect, setshowSelect] = useState(false);
  function changeSelectSelection() {
    setshowSelect(true);
  }

  return (
    <div className='stepfour-container'>
      <div className='stepfour-container-header-div'>
        <div>
          <p className='stepfour-container-header'>Service Requirement Details</p>
        </div>
        <div className='stepfour-container-prevbutton'>
          <button className='stepfour-button-prev' onClick={prevStep}>Go Back</button>
        </div>
      </div>
      <div className='container-flexbox2'>
        <div className='stepfour-propertytype-container'>
          <p className='stepfour-propertytype-container-subheader'>Your Property Type <span style={{ color:'red' }}>*</span></p>
          {formData.ServiceType.includes('Residential') ?
          <select className='propertytype-dropdown' name='PropertyType' value={formData.PropertyType} onChange={handleChange} onClick={changeSelectSelection}>
            <option value="" disabled={showSelect}>Select</option>
            <option value="Apartment">Apartment</option>
            <option value="Penthouse">Penthouse</option>
            <option value="Stand Alone Building">Stand Alone Building</option>
            <option value="Independent Home">Independent Home</option>
            <option value="Villa">Villa</option>
            <option value="Bungalow">Bungalow</option>
            {/* <option value="Retail">Retail</option>
            <option value="Hospitality">Hospitality</option>
            <option value="Healthcare">Healthcare</option>
            <option value="Office – Corporate and Tech">Office – Corporate and Tech</option>
            <option value="Institutional">Institutional</option>
            <option value="Industrial">Industrial</option> */}
          </select>
            :
          <select className='propertytype-dropdown' name='PropertyType' value={formData.PropertyType} onChange={handleChange} onClick={changeSelectSelection}>
            <option value="" disabled={showSelect}>Select</option>
            <option value="Retail">Retail</option>
            <option value="Hospitality">Hospitality</option>
            <option value="Healthcare">Healthcare</option>
            <option value="Office – Corporate and Tech">Office – Corporate and Tech</option>
            <option value="Institutional">Institutional</option>
            <option value="Industrial">Industrial</option>
          </select>
            }
        </div>

        <div className='stepfour-servicerequirement-container'>
          <p className='stepfour-propertytype-container-subheader'>Your Service Requirements <span style={{ color:'red' }}>*</span></p>
          <select className='propertytype-dropdown' name='ServiceRequirements' value={formData.ServiceRequirements} onChange={handleChange} onClick={changeSelectSelection}>
          <option value="" disabled={showSelect}>Select</option>
            <option value="Only Designs">Only Designs</option>
            <option value="Only Execution">Only Execution</option>
            <option value="Both – Designs and Execution">Both – Designs and Execution</option>
          </select>
        </div>

        <div className='stepfour-startofwork-container'>
          <p className='stepfour-propertytype-container-subheader'>When do you want to start the work <span style={{ color:'red' }}>*</span></p>
          <select className='propertytype-dropdown' name='StartOfTheWork' value={formData.StartOfTheWork} onChange={handleChange} onClick={changeSelectSelection}>
            <option value="" disabled={showSelect}>Select</option>
            <option value="Within 1 month">Within 1 month</option>
            <option value="Within 2 months">Within 2 months</option>
            <option value="Within 3 months">Within 3 months</option>
            <option value="Within 4 months">Within 4 months</option>
            <option value="Within 5 months">Within 5 months</option>
            <option value="Within 6 months">Within 6 months</option>
            <option value="Within 7 months">Within 7 months</option>
            <option value="Within 8 months">Within 8 months</option>
            <option value="Within 9 months">Within 9 months</option>
            <option value="Within 10 months">Within 10 months</option>
            <option value="Within 11 months">Within 11 months</option>
            <option value="Within 12 months">Within 12 months</option>
          </select>
        </div>

        <div className='stepfour-description-container'>
        <p className='stepfour-propertytype-container-subheader'>Please describe your property requirements completely and as clearly as possible. <span style={{ color:'red' }}>*</span></p>
          <textarea 
            className='descriptionprompt-inputbox'
            name='DescriptionalPrompt' 
            value={DescriptionInput} 
            onChange={handleInputChange}
            required
          />
        </div>

        <div className='stepfour-button-container'>
          <div className='stepfour-button-container-style'>
        {!formData.PropertyType.trim() || !formData.ServiceRequirements.trim() || !formData.StartOfTheWork.trim() ? 
          <button 
          className='stepfour-button-next-disable'
          disabled={true}
          >Next</button>
        :
          <button 
          className='stepfour-button-next-enable'
          onClick={nextStep} 
          disabled={!formData.PropertyType.trim() || !formData.ServiceRequirements.trim() || !formData.StartOfTheWork.trim()}
          >Next</button>
          }
      </div>      
      </div>      
      </div>      
    </div>
  );
};

export default StepFour;
