import React, { useEffect, useState } from 'react';
import StepOne from './StepOne.js';
import StepTwo from './StepTwo.js';
import StepThree from './StepThree.js';
import StepFour from './StepFour.js';
import StepFive from './StepFive.js';
import { POLandingPage } from '../../../../MetaTags/tags.js';


const NewFormPropertyOwner = () => {

  useEffect(() => {
    POLandingPage();    
  });

    const [formData, setFormData] = useState({
        name: '',
        phonenumber: '',
        email: '',
        city: '',
        areaName: '',
        pincode: '',
        ServiceType: '',
        ProjectArea: '',
        ResidentailRequirementsString: '',
        ResidentailRequirements: {
          FullHomeInterior: false,
          Bathroom: false,
          Bedroom: false,
          DisabledHandicap: false,
          EntertainmentArea: false,
          FitnessArea: false,
          HealthSafety: false,
          KitchenDining: false,
          LivingRoom: false,
          PrayerRoom: false,
          Outdoor: false,
          PassageWays: false,
          ProductivityArea: false,
          SecuritySystems: false,
          Utility: false,
        },
        PropertyRequirementsString: '',
        PropertyRequierments: {
          AppliancesEquipment: false,
          Architectural: false,
          AudioVisualSystems: false,
          CablingWiringNetworking: false,
          CeilingFinishes: false,
          DisabilityAccessibility: false,
          Electrical: false,
          EnergyEfficiency: false,
          FireSafety: false,
          FloorFinishes: false,
          Flooring: false,
          Foundation: false,
          Furniture: false,
          Hardware: false,
          HVAC: false,
          Insulation: false,
          InteriorDecoration: false,
          LightingDesignFixtures: false,
          Painting: false,
          PlumbingFixtures: false,
          Plumbing: false,
          Roofing: false,
          SmartHomeSystems: false,
          SustainableMaterials: false,
          WallFinishes: false,
          Walls: false,
          WaterEfficiency: false,
          WindowsDoors: false,
          CompleteEndToEndWork: false
        },
        // Appartment, penthouse etc...
        PropertyType: '',
        // only Design, only Exections etc...
        ServiceRequirements: '',
        // within 1 months, etc...
        StartOfTheWork: '',
        // prompt description chat-GPT
        DescriptionalPrompt: '',
        // chat-GPT Prompt called
        gptAICalled: false,
      });
    
      const [currentStep, setCurrentStep] = useState(1);
      const [isSubmitted, setIsSubmitted] = useState(false);
      const totalSteps = 5;
      const [completionPercentage, setCompletionPercentage] = useState(0);
    
      const handleChange = (e) => {
        // console.log("Form Valued: ", formData);
        
        const { name, value } = e.target;
        if(name === 'phonenumber'){
          if (/^\d{0,10}$/.test(value)) {
            setFormData((prevData) => ({
              ...prevData,
              [name]: value,
            }));
          }
        } else{
          setFormData({ ...formData, [name]: value });
        }
      };
    
      const nextStep = () => {
        setCurrentStep(currentStep + 1);
      };
    
      const prevStep = () => {
        setCurrentStep(currentStep - 1);
      };
    
      const handleSubmit = (e) => {
        e.preventDefault();
        // console.log('Form submitted:', formData);
        setIsSubmitted(true);
      };
    
      const updateProgress = (percentage) => {
        setCompletionPercentage(percentage);
      };
    
      // Calculate the total progress percentage
      const stepCompletionPercentage = ((currentStep - 1) / totalSteps) * 100;
      const totalProgressPercentage = Math.min(stepCompletionPercentage + completionPercentage, 100); // Cap at 100%
    
      return (
        <div>
          <div style={{ marginBottom: '20px' }}>
            <div
              style={{
                width: '100%',
                backgroundColor: '#e0e0df',
                borderRadius: '5px',
                overflow: 'hidden'
              }}
            >
              <div
                style={{
                  height: '15px',
                  width: `${totalProgressPercentage}%`,
                  backgroundColor: '#00C5FF',
                  transition: 'width 0.3s ease-in-out'
                }}
              />
            </div>
            <div style={{ textAlign: 'center', fontSize: '1.2em',fontWeight: '500', color: '#686868' }}>
              {totalProgressPercentage.toFixed(0)}% Complete
            </div>
          </div>
    
            <form onSubmit={handleSubmit}>
              {currentStep === 1 && (
                <StepOne formData={formData} handleChange={handleChange} nextStep={nextStep} />
              )}
              {currentStep === 2 && (
                <StepTwo
                  formData={formData}
                  handleChange={handleChange}
                  prevStep={prevStep}
                  nextStep={nextStep}
                />
              )}
              {currentStep === 3 && (
                <StepThree
                  formData={formData}
                  handleChange={handleChange}
                  prevStep={prevStep}
                  nextStep={nextStep}
                />
              )}
              {currentStep === 4 && (
                <StepFour
                  formData={formData}
                  handleChange={handleChange}
                  prevStep={prevStep}
                  nextStep={nextStep}
                  setFormData={setFormData}
                />
              )}
              {currentStep === 5 && (
                <StepFive
                  formData={formData}
                  handleChange={handleChange}
                  prevStep={prevStep}
                  handleSubmit={handleSubmit}
                  setProgress={updateProgress}
                />
              )}
            </form>
          {/* )} */}
        </div>
      );
    };

export default NewFormPropertyOwner