import axios from "axios";
import { CHATGPT_SERVER } from "../Config";
import { CHATGPT_PROMPT_FOR_PROPERTYOWNER_ADD } from "./types";

export function chatgptAIPrompt(dataToSubmit) {
    // console.log("Data to submit: ", dataToSubmit);
    
    const request = axios
      .post(`${CHATGPT_SERVER}/chatgptPrompt`, dataToSubmit)
      .then((response) => response.data);
  
    return {
      type: CHATGPT_PROMPT_FOR_PROPERTYOWNER_ADD,
      payload: request,
    };
  }