// src/components/StepThree.js
import React from 'react';
import './StepThree.css';

const StepThree = ({ formData, handleChange, prevStep, nextStep }) => {

    const handleCheckboxChange = (e) => {
      // console.log(e.target.checked);
      
      if(e.target.name === "Appliances and Equipment"){
        if(e.target.checked === true){
          formData.PropertyRequierments.AppliancesEquipment = true;
          formData.PropertyRequirementsString = formData.PropertyRequirementsString + "Appliances and Equipment, ";
        }
        if(e.target.checked === false){
          formData.PropertyRequierments.AppliancesEquipment = false;
          const newItems = formData.PropertyRequirementsString.replace("Appliances and Equipment, ", "");
          formData.PropertyRequirementsString = newItems;
        }
      }
      if(e.target.name === "Architectural"){
        if(e.target.checked === true){
          formData.PropertyRequierments.Architectural = true;
          formData.PropertyRequirementsString = formData.PropertyRequirementsString + "Architectural, ";
        }
        if(e.target.checked === false){
          formData.PropertyRequierments.Architectural = false;
          const newItems = formData.PropertyRequirementsString.replace("Architectural, ", "");
          formData.PropertyRequirementsString = newItems;
        }
      }
      if(e.target.name === "Audio-Visual Systems: Home theatre, speakers, etc."){
        if(e.target.checked === true){
          formData.PropertyRequierments.AudioVisualSystems = true;
          formData.PropertyRequirementsString = formData.PropertyRequirementsString + "Audio-Visual Systems: Home theatre, speakers, etc., ";
        }
        if(e.target.checked === false){
          formData.PropertyRequierments.AudioVisualSystems = false;
          const newItems = formData.PropertyRequirementsString.replace("Audio-Visual Systems: Home theatre, speakers, etc., ", "");
          formData.PropertyRequirementsString = newItems;
        }
      }
      if(e.target.name === "Cabling, Wiring and Networking"){
        if(e.target.checked === true){
          formData.PropertyRequierments.CablingWiringNetworking = true;
          formData.PropertyRequirementsString = formData.PropertyRequirementsString + "Cabling, Wiring and Networking, ";
        }
        if(e.target.checked === false){
          formData.PropertyRequierments.CablingWiringNetworking = false;
          const newItems = formData.PropertyRequirementsString.replace("Cabling, Wiring and Networking, ", "");
          formData.PropertyRequirementsString = newItems;
        }
      }
      if(e.target.name === "Ceiling Finishes"){
        if(e.target.checked === true){
          formData.PropertyRequierments.CeilingFinishes = true;
          formData.PropertyRequirementsString = formData.PropertyRequirementsString + "Ceiling Finishes, ";
        }
        if(e.target.checked === false){
          formData.PropertyRequierments.CeilingFinishes = false;
          const newItems = formData.PropertyRequirementsString.replace("Ceiling Finishes, ", "");
          formData.PropertyRequirementsString = newItems;
        }
      }
      if(e.target.name === "Disability Accessibility"){
        if(e.target.checked === true){
          formData.PropertyRequierments.DisabilityAccessibility = true;
          formData.PropertyRequirementsString = formData.PropertyRequirementsString + "Disability Accessibility, ";
        }
        if(e.target.checked === false){
          formData.PropertyRequierments.DisabilityAccessibility = false;
          const newItems = formData.PropertyRequirementsString.replace("Disability Accessibility, ", "");
          formData.PropertyRequirementsString = newItems;
        }
      }
      if(e.target.name === "Electrical"){
        if(e.target.checked === true){
          formData.PropertyRequierments.Electrical = true;
          formData.PropertyRequirementsString = formData.PropertyRequirementsString + "Electrical, ";
        }
        if(e.target.checked === false){
          formData.PropertyRequierments.Electrical = false;
          const newItems = formData.PropertyRequirementsString.replace("Electrical, ", "");
          formData.PropertyRequirementsString = newItems;
        }
      }
      if(e.target.name === "Energy Efficiency"){
        if(e.target.checked === true){
          formData.PropertyRequierments.EnergyEfficiency = true;
          formData.PropertyRequirementsString = formData.PropertyRequirementsString + "Energy Efficiency, ";
        }
        if(e.target.checked === false){
          formData.PropertyRequierments.EnergyEfficiency = false;
          const newItems = formData.PropertyRequirementsString.replace("Energy Efficiency, ", "");
          formData.PropertyRequirementsString = newItems;
        }
      }
      if(e.target.name === "Fire Safety"){
        if(e.target.checked === true){
          formData.PropertyRequierments.FireSafety = true;
          formData.PropertyRequirementsString = formData.PropertyRequirementsString + "Fire Safety, ";
        }
        if(e.target.checked === false){
          formData.PropertyRequierments.FireSafety = false;
          const newItems = formData.PropertyRequirementsString.replace("Fire Safety, ", "");
          formData.PropertyRequirementsString = newItems;
        }
      }
      if(e.target.name === "Floor Finishes: Carpeting, hardwood, tiles, etc."){
        if(e.target.checked === true){
          formData.PropertyRequierments.FloorFinishes = true;
          formData.PropertyRequirementsString = formData.PropertyRequirementsString + "Floor Finishes: Carpeting, hardwood, tiles, etc., ";
        }
        if(e.target.checked === false){
          formData.PropertyRequierments.FloorFinishes = false;
          const newItems = formData.PropertyRequirementsString.replace("Floor Finishes: Carpeting, hardwood, tiles, etc., ", "");
          formData.PropertyRequirementsString = newItems;
        }
      }
      if(e.target.name === "Flooring: Installation of floor materials, etc."){
        if(e.target.checked === true){
          formData.PropertyRequierments.Flooring = true;
          formData.PropertyRequirementsString = formData.PropertyRequirementsString + "Flooring: Installation of floor materials, etc., ";
        }
        if(e.target.checked === false){
          formData.PropertyRequierments.Flooring = false;
          const newItems = formData.PropertyRequirementsString.replace("Flooring: Installation of floor materials, etc., ", "");
          formData.PropertyRequirementsString = newItems;
        }
      }
      if(e.target.name === "Foundation: Concrete footing and slab, etc."){
        if(e.target.checked === true){
          formData.PropertyRequierments.Foundation = true;
          formData.PropertyRequirementsString = formData.PropertyRequirementsString + "Foundation: Concrete footing and slab, etc., ";
        }
        if(e.target.checked === false){
          formData.PropertyRequierments.Foundation = false;
          const newItems = formData.PropertyRequirementsString.replace("Foundation: Concrete footing and slab, etc., ", "");
          formData.PropertyRequirementsString = newItems;
        }
      }
      if(e.target.name === "Furniture"){
        if(e.target.checked === true){
          formData.PropertyRequierments.Furniture = true;
          formData.PropertyRequirementsString = formData.PropertyRequirementsString + "Furniture, ";
        }
        if(e.target.checked === false){
          formData.PropertyRequierments.Furniture = false;
          const newItems = formData.PropertyRequirementsString.replace("Furniture, ", "");
          formData.PropertyRequirementsString = newItems;
        }
      }
      if(e.target.name === "Hardware"){
        if(e.target.checked === true){
          formData.PropertyRequierments.Hardware = true;
          formData.PropertyRequirementsString = formData.PropertyRequirementsString + "Hardware, ";
        }
        if(e.target.checked === false){
          formData.PropertyRequierments.Hardware = false;
          const newItems = formData.PropertyRequirementsString.replace("Hardware, ", "");
          formData.PropertyRequirementsString = newItems;
        }
      }
      if(e.target.name === "HVAC: Heating, cooling, ventilation, etc."){
        if(e.target.checked === true){
          formData.PropertyRequierments.HVAC = true;
          formData.PropertyRequirementsString = formData.PropertyRequirementsString + "HVAC: Heating, cooling, ventilation, etc., ";
        }
        if(e.target.checked === false){
          formData.PropertyRequierments.HVAC = false;
          const newItems = formData.PropertyRequirementsString.replace("HVAC: Heating, cooling, ventilation, etc., ", "");
          formData.PropertyRequirementsString = newItems;
        }
      }
      if(e.target.name === "Insulation"){
        if(e.target.checked === true){
          formData.PropertyRequierments.Insulation = true;
          formData.PropertyRequirementsString = formData.PropertyRequirementsString + "Insulation, ";
        }
        if(e.target.checked === false){
          formData.PropertyRequierments.Insulation = false;
          const newItems = formData.PropertyRequirementsString.replace("Insulation, ", "");
          formData.PropertyRequirementsString = newItems;
        }
      }
      if(e.target.name === "Interior Decoration: Artwork, accessories, rugs, etc."){
        if(e.target.checked === true){
          formData.PropertyRequierments.InteriorDecoration = true;
          formData.PropertyRequirementsString = formData.PropertyRequirementsString + "Interior Decoration: Artwork, accessories, rugs, etc., ";
        }
        if(e.target.checked === false){
          formData.PropertyRequierments.InteriorDecoration = false;
          const newItems = formData.PropertyRequirementsString.replace("Interior Decoration: Artwork, accessories, rugs, etc., ", "");
          formData.PropertyRequirementsString = newItems;
        }
      }
      if(e.target.name === "Lighting Design and Fixtures: Ceiling lights, lamps, etc."){
        if(e.target.checked === true){
          formData.PropertyRequierments.LightingDesignFixtures = true;
          formData.PropertyRequirementsString = formData.PropertyRequirementsString + "Lighting Design and Fixtures: Ceiling lights, lamps, etc., ";
        }
        if(e.target.checked === false){
          formData.PropertyRequierments.LightingDesignFixtures = false;
          const newItems = formData.PropertyRequirementsString.replace("Lighting Design and Fixtures: Ceiling lights, lamps, etc., ", "");
          formData.PropertyRequirementsString = newItems;
        }
      }
      if(e.target.name === "Painting: Interior or exterior painting."){
        if(e.target.checked === true){
          formData.PropertyRequierments.Painting = true;
          formData.PropertyRequirementsString = formData.PropertyRequirementsString + "Painting: Interior or exterior painting., ";
        }
        if(e.target.checked === false){
          formData.PropertyRequierments.Painting = false;
          const newItems = formData.PropertyRequirementsString.replace("Painting: Interior or exterior painting., ", "");
          formData.PropertyRequirementsString = newItems;
        }
      }
      if(e.target.name === "Plumbing Fixtures: Sinks, faucets, toilets, etc."){
        if(e.target.checked === true){
          formData.PropertyRequierments.PlumbingFixtures = true;
          formData.PropertyRequirementsString = formData.PropertyRequirementsString + "Plumbing Fixtures: Sinks, faucets, toilets, etc., ";
        }
        if(e.target.checked === false){
          formData.PropertyRequierments.PlumbingFixtures = false;
          const newItems = formData.PropertyRequirementsString.replace("Plumbing Fixtures: Sinks, faucets, toilets, etc., ", "");
          formData.PropertyRequirementsString = newItems;
        }
      }
      if(e.target.name === "Plumbing: Water supply, drainage systems, etc."){
        if(e.target.checked === true){
          formData.PropertyRequierments.Plumbing = true;
          formData.PropertyRequirementsString = formData.PropertyRequirementsString + "Plumbing: Water supply, drainage systems, etc., ";
        }
        if(e.target.checked === false){
          formData.PropertyRequierments.Plumbing = false;
          const newItems = formData.PropertyRequirementsString.replace("Plumbing: Water supply, drainage systems, etc., ", "");
          formData.PropertyRequirementsString = newItems;
        }
      }
      if(e.target.name === "Roofing"){
        if(e.target.checked === true){
          formData.PropertyRequierments.Roofing = true;
          formData.PropertyRequirementsString = formData.PropertyRequirementsString + "Roofing, ";
        }
        if(e.target.checked === false){
          formData.PropertyRequierments.Roofing = false;
          const newItems = formData.PropertyRequirementsString.replace("Roofing, ", "");
          formData.PropertyRequirementsString = newItems;
        }
      }
      if(e.target.name === "Smart Home Systems: Home automation."){
        if(e.target.checked === true){
          formData.PropertyRequierments.SmartHomeSystems = true;
          formData.PropertyRequirementsString = formData.PropertyRequirementsString + "Smart Home Systems: Home automation., ";
        }
        if(e.target.checked === false){
          formData.PropertyRequierments.SmartHomeSystems = false;
          const newItems = formData.PropertyRequirementsString.replace("Smart Home Systems: Home automation., ", "");
          formData.PropertyRequirementsString = newItems;
        }
      }
      if(e.target.name === "Sustainable Materials: Eco-friendly materials."){
        if(e.target.checked === true){
          formData.PropertyRequierments.SustainableMaterials = true;
          formData.PropertyRequirementsString = formData.PropertyRequirementsString + "Sustainable Materials: Eco-friendly materials., ";
        }
        if(e.target.checked === false){
          formData.PropertyRequierments.SustainableMaterials = false;
          const newItems = formData.PropertyRequirementsString.replace("Sustainable Materials: Eco-friendly materials., ", "");
          formData.PropertyRequirementsString = newItems;
        }
      }
      if(e.target.name === "Wall Finishes: Paint, wallpaper, tiles, etc."){
        if(e.target.checked === true){
          formData.PropertyRequierments.WallFinishes = true;
          formData.PropertyRequirementsString = formData.PropertyRequirementsString + "Wall Finishes: Paint, wallpaper, tiles, etc., ";
        }
        if(e.target.checked === false){
          formData.PropertyRequierments.WallFinishes = false;
          const newItems = formData.PropertyRequirementsString.replace("Wall Finishes: Paint, wallpaper, tiles, etc., ", "");
          formData.PropertyRequirementsString = newItems;
        }
      }
      if(e.target.name === "Walls: Drywall, plastering, etc."){
        if(e.target.checked === true){
          formData.PropertyRequierments.Walls = true;
          formData.PropertyRequirementsString = formData.PropertyRequirementsString + "Walls: Drywall, plastering, etc., ";
        }
        if(e.target.checked === false){
          formData.PropertyRequierments.Walls = false;
          const newItems = formData.PropertyRequirementsString.replace("Walls: Drywall, plastering, etc., ", "");
          formData.PropertyRequirementsString = newItems;
        }
      }
      if(e.target.name === "Water Efficiency: Minimize water usage"){
        if(e.target.checked === true){
          formData.PropertyRequierments.WaterEfficiency = true;
          formData.PropertyRequirementsString = formData.PropertyRequirementsString + "Water Efficiency: Minimize water usage, ";
        }
        if(e.target.checked === false){
          formData.PropertyRequierments.WaterEfficiency = false;
          const newItems = formData.PropertyRequirementsString.replace("Water Efficiency: Minimize water usage, ", "");
          formData.PropertyRequirementsString = newItems;
        }
      }
      if(e.target.name === "Windows and Doors: Installation of windows and doors."){
        if(e.target.checked === true){
          formData.PropertyRequierments.WindowsDoors = true;
          formData.PropertyRequirementsString = formData.PropertyRequirementsString + "Windows and Doors: Installation of windows and doors., ";
        }
        if(e.target.checked === false){
          formData.PropertyRequierments.WindowsDoors = false;
          const newItems = formData.PropertyRequirementsString.replace("Windows and Doors: Installation of windows and doors., ", "");
          formData.PropertyRequirementsString = newItems;
        }
      }
      if(e.target.name === "Complete End To End Work"){
        if(e.target.checked === true){
          formData.PropertyRequierments.CompleteEndToEndWork = true;
          formData.PropertyRequirementsString = formData.PropertyRequirementsString + "Complete End To End Work, ";
        }
        if(e.target.checked === false){
          formData.PropertyRequierments.CompleteEndToEndWork = false;
          const newItems = formData.PropertyRequirementsString.replace("Complete End To End Work, ", "");
          formData.PropertyRequirementsString = newItems;
        }
      }
    }

  return (
    <div className='propertyrequirements-container'>
      <div className='propertyrequirements-container-header-div'>
        <div>
          <p className='propertyrequirements-container-header'>Complete Property Requirements</p>
        </div>
        <div className='propertyRequirements-backbutton'>
          <button className='propertyRequirements-button-prev' onClick={prevStep}>Go Back</button>
        </div>
      </div>
      <div className='div-checkbox-continer' value={formData.PropertyRequierments} onChange={handleChange}>
        {/* <div className='div-checkbox-continer-header-div'> */}
          <p className='div-checkbox-continer-header'>Overall Property Requirements<span style={{ color: 'red' }}>*</span></p>
        {/* </div> */}
        <div className='div-checkbox-continer-div'>
          <label className='prs-checkbox-label'>
              <input
                className='prs-checkbox-inputbox'
                type="checkbox"
                name="Appliances and Equipment"
                onChange={handleCheckboxChange}
                defaultChecked={formData.PropertyRequierments.AppliancesEquipment}
              />
              Appliances and Equipment
          </label>
        </div>
          <div className='div-checkbox-continer-div'>
        <label className='prs-checkbox-label'>
            <input
              className='prs-checkbox-inputbox'
              type="checkbox"
              name="Architectural"
              onChange={handleCheckboxChange}
              defaultChecked={formData.PropertyRequierments.Architectural}
            />
            Architectural
          </label>
          </div>
          <div className='div-checkbox-continer-div'>
        <label className='prs-checkbox-label'>
            <input
              className='prs-checkbox-inputbox'
              type="checkbox"
              name="Audio-Visual Systems: Home theatre, speakers, etc."
              onChange={handleCheckboxChange}
              defaultChecked={formData.PropertyRequierments.AudioVisualSystems}
            />
            Audio-Visual Systems: Home theatre, speakers, etc.
          </label>
          </div>
          <div className='div-checkbox-continer-div'>
        <label className='prs-checkbox-label'>
            <input
              className='prs-checkbox-inputbox'
              type="checkbox"
              name="Cabling, Wiring and Networking"
              onChange={handleCheckboxChange}
              defaultChecked={formData.PropertyRequierments.CablingWiringNetworking}
            />
            Cabling, Wiring and Networking
          </label>
          </div>
          <div className='div-checkbox-continer-div'>
        <label className='prs-checkbox-label'>
            <input
              className='prs-checkbox-inputbox'
              type="checkbox"
              name="Ceiling Finishes"
              onChange={handleCheckboxChange}
              defaultChecked={formData.PropertyRequierments.CeilingFinishes}
            />
            Ceiling Finishes
          </label>
          </div>
          <div className='div-checkbox-continer-div'>
        <label className='prs-checkbox-label'>
            <input
              className='prs-checkbox-inputbox'
              type="checkbox"
              name="Disability Accessibility"
              onChange={handleCheckboxChange}
              defaultChecked={formData.PropertyRequierments.DisabilityAccessibility}
            />
            Disability Accessibility
          </label>
          </div>
          <div className='div-checkbox-continer-div'>
        <label className='prs-checkbox-label'>
            <input
              className='prs-checkbox-inputbox'
              type="checkbox"
              name="Electrical"
              onChange={handleCheckboxChange}
              defaultChecked={formData.PropertyRequierments.Electrical}
            />
            Electrical
          </label>
          </div>
          <div className='div-checkbox-continer-div'>
        <label className='prs-checkbox-label'>
            <input
              className='prs-checkbox-inputbox'
              type="checkbox"
              name="Energy Efficiency"
              onChange={handleCheckboxChange}
              defaultChecked={formData.PropertyRequierments.EnergyEfficiency}
            />
            Energy Efficiency
          </label>
          </div>
          <div className='div-checkbox-continer-div'>
        <label className='prs-checkbox-label'>
            <input
              className='prs-checkbox-inputbox'
              type="checkbox"
              name="Fire Safety"
              onChange={handleCheckboxChange}
              defaultChecked={formData.PropertyRequierments.FireSafety}
            />
            Fire Safety
          </label>
          </div>
          <div className='div-checkbox-continer-div'>
        <label className='prs-checkbox-label'>
            <input
              className='prs-checkbox-inputbox'
              type="checkbox"
              name="Floor Finishes: Carpeting, hardwood, tiles, etc."
              onChange={handleCheckboxChange}
              defaultChecked={formData.PropertyRequierments.FloorFinishes}
            />
            Floor Finishes: Carpeting, hardwood, tiles, etc.
          </label>
          </div>
          <div className='div-checkbox-continer-div'>
        <label className='prs-checkbox-label'>
            <input
              className='prs-checkbox-inputbox'
              type="checkbox"
              name="Flooring: Installation of floor materials, etc."
              onChange={handleCheckboxChange}
              defaultChecked={formData.PropertyRequierments.Flooring}
            />
            Flooring: Installation of floor materials, etc.
          </label>
          </div>
          <div className='div-checkbox-continer-div'>
        <label className='prs-checkbox-label'>
            <input
              className='prs-checkbox-inputbox'
              type="checkbox"
              name="Foundation: Concrete footing and slab, etc."
              onChange={handleCheckboxChange}
              defaultChecked={formData.PropertyRequierments.Foundation}
            />
            Foundation: Concrete footing and slab, etc.
          </label>
          </div>
          <div className='div-checkbox-continer-div'>
        <label className='prs-checkbox-label'>
            <input
              className='prs-checkbox-inputbox'
              type="checkbox"
              name="Furniture"
              onChange={handleCheckboxChange}
              defaultChecked={formData.PropertyRequierments.Furniture}
            />
            Furniture
          </label>
          </div>
          <div className='div-checkbox-continer-div'>
        <label className='prs-checkbox-label'>
            <input
              className='prs-checkbox-inputbox'
              type="checkbox"
              name="Hardware"
              onChange={handleCheckboxChange}
              defaultChecked={formData.PropertyRequierments.Hardware}
            />
            Hardware
          </label>
          </div>
          <div className='div-checkbox-continer-div'>
        <label className='prs-checkbox-label'>
            <input
              className='prs-checkbox-inputbox'
              type="checkbox"
              name="HVAC: Heating, cooling, ventilation, etc."
              onChange={handleCheckboxChange}
              defaultChecked={formData.PropertyRequierments.HVAC}
            />
            HVAC: Heating, cooling, ventilation, etc.
          </label>
          </div>
          <div className='div-checkbox-continer-div'>
        <label className='prs-checkbox-label'>
            <input
              className='prs-checkbox-inputbox'
              type="checkbox"
              name="Insulation"
              onChange={handleCheckboxChange}
              defaultChecked={formData.PropertyRequierments.Insulation}
            />
            Insulation
          </label>
          </div>
          <div className='div-checkbox-continer-div'>
        <label className='prs-checkbox-label'>
            <input
              className='prs-checkbox-inputbox'
              type="checkbox"
              name="Interior Decoration: Artwork, accessories, rugs, etc."
              onChange={handleCheckboxChange}
              defaultChecked={formData.PropertyRequierments.InteriorDecoration}
            />
            Interior Decoration: Artwork, accessories, rugs, etc.
          </label>
          </div>
          <div className='div-checkbox-continer-div'>
        <label className='prs-checkbox-label'>
            <input
              className='prs-checkbox-inputbox'
              type="checkbox"
              name="Lighting Design and Fixtures: Ceiling lights, lamps, etc."
              onChange={handleCheckboxChange}
              defaultChecked={formData.PropertyRequierments.LightingDesignFixtures}
            />
            Lighting Design and Fixtures: Ceiling lights, lamps, etc.
          </label>
          </div>
          <div className='div-checkbox-continer-div'>
        <label className='prs-checkbox-label'>
            <input
              className='prs-checkbox-inputbox'
              type="checkbox"
              name="Painting: Interior or exterior painting."
              onChange={handleCheckboxChange}
              defaultChecked={formData.PropertyRequierments.Painting}
            />
            Painting: Interior or exterior painting.
          </label>
          </div>
          <div className='div-checkbox-continer-div'>
        <label className='prs-checkbox-label'>
            <input
              className='prs-checkbox-inputbox'
              type="checkbox"
              name="Plumbing Fixtures: Sinks, faucets, toilets, etc."
              onChange={handleCheckboxChange}
              defaultChecked={formData.PropertyRequierments.PlumbingFixtures}
            />
            Plumbing Fixtures: Sinks, faucets, toilets, etc.
          </label>
          </div>
          <div className='div-checkbox-continer-div'>
        <label className='prs-checkbox-label'>
            <input
              className='prs-checkbox-inputbox'
              type="checkbox"
              name="Plumbing: Water supply, drainage systems, etc."
              onChange={handleCheckboxChange}
              defaultChecked={formData.PropertyRequierments.Plumbing}
            />
            Plumbing: Water supply, drainage systems, etc.
          </label>
          </div>
          <div className='div-checkbox-continer-div'>
        <label className='prs-checkbox-label'>
            <input
              className='prs-checkbox-inputbox'
              type="checkbox"
              name="Roofing"
              onChange={handleCheckboxChange}
              defaultChecked={formData.PropertyRequierments.Roofing}
            />
            Roofing
          </label>
          </div>
          <div className='div-checkbox-continer-div'>
        <label className='prs-checkbox-label'>
            <input
              className='prs-checkbox-inputbox'
              type="checkbox"
              name="Smart Home Systems: Home automation."
              onChange={handleCheckboxChange}
              defaultChecked={formData.PropertyRequierments.SmartHomeSystems}
            />
            Smart Home Systems: Home automation.
          </label>
          </div>
          <div className='div-checkbox-continer-div'>
        <label className='prs-checkbox-label'>
            <input
              className='prs-checkbox-inputbox'
              type="checkbox"
              name="Sustainable Materials: Eco-friendly materials."
              onChange={handleCheckboxChange}
              defaultChecked={formData.PropertyRequierments.SustainableMaterials}
            />
            Sustainable Materials: Eco-friendly materials.
          </label>
          </div>
          <div className='div-checkbox-continer-div'>
        <label className='prs-checkbox-label'>
            <input
              className='prs-checkbox-inputbox'
              type="checkbox"
              name="Wall Finishes: Paint, wallpaper, tiles, etc."
              onChange={handleCheckboxChange}
              defaultChecked={formData.PropertyRequierments.WallFinishes}
            />
            Wall Finishes: Paint, wallpaper, tiles, etc.
          </label>
          </div>
          <div className='div-checkbox-continer-div'>
        <label className='prs-checkbox-label'>
            <input
              className='prs-checkbox-inputbox'
              type="checkbox"
              name="Walls: Drywall, plastering, etc."
              onChange={handleCheckboxChange}
              defaultChecked={formData.PropertyRequierments.Walls}
            />
            Walls: Drywall, plastering, etc.
          </label>
          </div>
          <div className='div-checkbox-continer-div'>
        <label className='prs-checkbox-label'>
            <input
              className='prs-checkbox-inputbox'
              type="checkbox"
              name="Water Efficiency: Minimize water usage"
              onChange={handleCheckboxChange}
              defaultChecked={formData.PropertyRequierments.WaterEfficiency}
            />
            Water Efficiency: Minimize water usage
          </label>
          </div>
          <div className='div-checkbox-continer-div'>
        <label className='prs-checkbox-label'>
            <input
              className='prs-checkbox-inputbox'
              type="checkbox"
              name="Windows and Doors: Installation of windows and doors."
              onChange={handleCheckboxChange}
              defaultChecked={formData.PropertyRequierments.WindowsDoors}
            />
            Windows and Doors: Installation of windows and doors.
          </label>
          </div>
          <div className='div-checkbox-continer-div'>
            <label className='prs-checkbox-label'>
                <input
                  className='prs-checkbox-inputbox'
                  type="checkbox"
                  name="Complete End To End Work"
                  onChange={handleCheckboxChange}
                  defaultChecked={formData.PropertyRequierments.CompleteEndToEndWork}
                />
                Complete End To End Work
              </label>
          </div>
      <div className='prs-button-container'>
      {!formData.PropertyRequierments.AppliancesEquipment && !formData.PropertyRequierments.Architectural &&
          !formData.PropertyRequierments.AudioVisualSystems && !formData.PropertyRequierments.CablingWiringNetworking &&
          !formData.PropertyRequierments.CeilingFinishes && !formData.PropertyRequierments.DisabilityAccessibility &&
          !formData.PropertyRequierments.Electrical && !formData.PropertyRequierments.EnergyEfficiency &&
          !formData.PropertyRequierments.FireSafety && !formData.PropertyRequierments.FloorFinishes &&
          !formData.PropertyRequierments.Flooring && !formData.PropertyRequierments.Foundation &&
          !formData.PropertyRequierments.Furniture && !formData.PropertyRequierments.Hardware &&
          !formData.PropertyRequierments.HVAC && !formData.PropertyRequierments.Insulation &&
          !formData.PropertyRequierments.InteriorDecoration && !formData.PropertyRequierments.LightingDesignFixtures &&
          !formData.PropertyRequierments.Painting && !formData.PropertyRequierments.PlumbingFixtures &&
          !formData.PropertyRequierments.Plumbing && !formData.PropertyRequierments.Roofing &&
          !formData.PropertyRequierments.SmartHomeSystems && !formData.PropertyRequierments.SustainableMaterials &&
          !formData.PropertyRequierments.WallFinishes && !formData.PropertyRequierments.Walls &&
          !formData.PropertyRequierments.WaterEfficiency && !formData.PropertyRequierments.WindowsDoors &&
          !formData.PropertyRequierments.CompleteEndToEndWork ? 
          
      <button
      className='propertyRequirements-button-next-disable'
      disabled={true}
      >Next</button>
      :
      <button 
      className='propertyRequirements-button-next-enable'
        onClick={nextStep} 
      >Next</button>
      }
      </div>
      </div>
    </div>
  );
};

export default StepThree;
