import React, { Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Auth from "./hoc/auth";
import CompanyAuth from "./hoc/company_auth";
import Nav from "./Components/Views/NavBar/Nav";
import Login from "./Components/Views/Login/PropertyOwner/Login";
import Property from "./Components/Views/LandingPage/Property/Property";
import VerifyBeforePostProperty from "./Components/Views/PropertyOwner/UploadPropertyRequirement/VerifyBeforePostProperty";
import SPLogin from "./Components/Views/Login/ServiceProfessional/SPLogin";
import Pricing from "./Components/Views/Pricing/Pricing";
import CartPage from "./Components/Views/CartPage/CartPage";
import PO_Register from "./Components/Views/Register/PO_Register/PO_Register";
import SP_Register from "./Components/Views/Register/SP_Register/SP_Register";
// import TransactionTable from "./Components/Views/ServiceProfessional/TransactionTable/TransactionTable";
import MyPurchasedLeadTable from "./Components/Views/ServiceProfessional/MyPurchasedLead/MyPurchasedLeadTable";
import PropertyDetails from "./Components/Views/LandingPage/Property-Details/PropertyDetails";
import Projects from "./Components/Views/LandingPage/Projects/Projects";
import ProjectDetails from "./Components/Views/LandingPage/Project_Details/ProjectDetails";
import POTransactionPage from "./Components/Views/PropertyOwner/TransactionPage/POTransactionPage";

import Home from "./Components/Views/Home/Home";
import EditPropertyDetails from "./Components/Views/PropertyOwner/EditPropertyDetails/EditPropertyDetails";
import RenewPropertyDetails from "./Components/Views/PropertyOwner/RenewPropertyDetails/RenewPropertyDetails";
import ProfilePage from "./Components/Views/Profiles/Service_Professional/Profile/ProfilePage/ProfilePage";
import PO_MyProfile from "./Components/Views/Profiles/Property_Owner/MyProfile/PO_MyProfile";
import SP_Profile from "./Components/Views/Profiles/Service_Professional/SP_Profile/SP_Profile";
import SP_UploadNewProject from "./Components/Views/Profiles/Service_Professional/Project/SP_UploadNewProject";
import LoadingIcon from "./Components/utils/LoadingIcon/LoadingIcon";
import EditPreviouslyUploadedProjectDetailedPage from "./Components/Views/Profiles/Service_Professional/Profile/Views/EditPreviouslyUploadedProjectDetailedPage/EditPreviouslyUploadedProjectDetailedPage";
import PostedPropertyDetails from "./Components/Views/PropertyOwner/Views/PostedPropertyDetails/PostedPropertyDetails";
import EditedPropertyDetails from "./Components/Views/PropertyOwner/Views/EditedPropertyDetails/EditedPropertyDetails";
import SP_Analytics from "./Components/Views/Analytics/ServiceProfessional/SP_Analytics";
import PO_Analytics from "./Components/Views/Analytics/PropertyOwner/PO_Analytics";
import About from "./Components/Views/About/About";
import TestimonialsAndReviews from "./Components/Views/Testimonials And Reviews/TestimonialsAndReviews";
import PO_ForgotPassword from "./Components/Views/ForgotPassword/PropertyOwner/PO_ForgotPassword";
import PO_ResetPassword from "./Components/Views/ForgotPassword/PropertyOwner/PO_ResetPassword";
import SP_ForgotPassword from "./Components/Views/ForgotPassword/ServiceProfessional/SP_ForgotPassword";
import SP_ResetPassword from "./Components/Views/ForgotPassword/ServiceProfessional/SP_ResetPassword";
import Gallery from "./Components/Views/Gallery/ServiceProfessional/Gallery/Gallery";
import Footer from "./Components/utils/Footer/Footer";
import PreviouslyProjectDetailsPage from "./Components/Views/Profiles/Service_Professional/PreviouslyProjectDetailsPage/PreviouslyProjectDetailsPage";
import SuccessPage from "./Components/Views/CartPage/SuccessPage/SuccessPage";
import SPSuccess from "./Components/Views/CartPage/SPSuccess/SPSuccess";
import OtherViewProfile from "./Components/Views/Profiles/Property_Owner/OtherView/OtherViewProfile";
// import SP_Profile_OtherView from "./Components/Views/Profiles/Service_Professional/OtherView/SPProfileOtherView";
import RefundReplacement from "./Components/Views/ExtraPages/RefundReplacement/RefundReplacement";
import ComingSoon from "./Components/Views/ExtraPages/ComingSoon/Comingsoon";
import ContactUs from "./Components/Views/ExtraPages/ContactUs/ContactUs";
import Terms_Conditions from "./Components/Views/Terms&Conditions/Terms_Conditions";
import Privacy_Policy from "./Components/Views/Privacy_Policy/Privacy_Policy";
import RenewedPropertyDetails from "./Components/Views/PropertyOwner/Views/RenewedPropertyDetails/RenewedPropertyDetails";
import PORules from "./Components/Views/Rules/PO/Rules";
import SPRules from "./Components/Views/Rules/SP/Rules";
import SignInRequired from "./Components/Views/LandingPage/ServiceProfessionalPage/SignInRequired/SignInRequired";
import PO_Pricing from "./Components/Views/Profiles/Property_Owner/Pricing/PO_Pricing";
import VerificationBeforeDetails from "./Components/Views/LandingPage/ServiceProfessionalPage/VerificationBeforeDetails/VerificationBeforeDetails";
import UploadedPropertyDetails from "./Components/Views/Profiles/Property_Owner/MyProfile/PO_UploadedProperties/UploadedPropertyDetails/UploadedPropertyDetails";
import VerifyProjectDetailsBeforeLogin from "./Components/Views/Profiles/Service_Professional/OtherView/Verification/VerifyProjectDetailsBeforeLogin";
import Pagination from "./Components/Pagination/Pagination";
import Declarationcontent from "./Components/utils/DeclarationContent/Declarationcontent";
import Freeresources from "./Components/Views/FreeResources/Freeresources";
import PostPropertyWithoutAuth from "./Components/Views/PostPropertyWithoutAuth/PostPropertyWithoutAuth";
import ThankYou from "./Components/Views/PostPropertyWithoutAuth/ThankYou/ThankYou";
import Propertywithoutauth from "./Components/Views/WithoutAuth/PropertyOwner/Propertywithoutauth";
// import WebinarPricing from "./Components/Views/WebinarPricing/WebinarPricing";
// import PostPricing from "./Components/Views/PostPricing/PostPricing";
import MembershipPricing from "./Components/Views/MembershipPricing/MembershipPricing";
import CommunityGuidelines from "./Components/Views/CommunityGuidelines/CommunityGuidelines";
import ReplacementPage from "./Components/Views/ReplacementPage/ReplacementPage";
import UnsubscribeEmailNotification from "./Components/Views/UnsubscribeEmailNotification/UnsubscribeEmailNotification";
import MyStats from "./Components/Views/NavBar/DesktopNav/MyStats";
import NewFormPropertyOwner from "./Components/Views/WithoutAuth/NewFormPropertyOwner/NewFormPropertyOwner";
import ThankYouForPODetails from "./Components/Views/WithoutAuth/NewFormPropertyOwner/ThankYouForPODetails";

function App() {
  return (
    <Suspense
      fallback={
        <div>
          <LoadingIcon />
        </div>
      }
    >
      <Nav />
      <div className="app-container">
        <Switch>
          {/* COMMON PAGES LINK */}
          <Route exact path="/" component={CompanyAuth(Home, null)} />
          <Route exact path="/Home" component={Auth(Home, null)} />
          <Route exact path="/Projects" component={Auth(Projects, null)} />
          <Route exact path="/Gallery" component={Auth(Gallery, null)} />
          <Route exact path="/OtherViewProfile/PropertyOwner/:ProfileID" component={CompanyAuth(OtherViewProfile, true)} />
          <Route exact path="/ProjectsDetails/:ProjectID" component={Auth(ProjectDetails, null)} />
          <Route exact path="/AllServiceProfessionalPage/SP/Profile/OtherView/:profileID" component={Auth(VerifyProjectDetailsBeforeLogin, true)} />
          <Route exact path="/AllServiceProfessionalPage/Profile/OtherView/:profileID" component={Auth(VerifyProjectDetailsBeforeLogin, null)} />
          <Route exact path="/AllServiceProfessionalPage" component={Auth(VerificationBeforeDetails, null)} />
          {/* <Route exact path="/service" component={Services} />
          <Route exact path="/servicedetails" component={ServiceDetails} /> */}

          {/* PROPERTY OWNER */}
          <Route exact path="/PropertyOwner/Home" component={Auth(Home, true)} />
          <Route exact path="/PropertyOwner/Login" component={Auth(Login, false)} />
          <Route exact path="/propertyowner/postpropertyrequirements" component={Auth(VerifyBeforePostProperty, true)} />
          <Route exact path="/PropertyOwner/MyProfile" component={Auth(PO_MyProfile, true)} />
          <Route exact path="/PropertyOwner/POPricing" component={Auth(PO_Pricing, true)} />
          <Route exact path="/PropertyOwner/Register" component={Auth(PO_Register, false)} />
          <Route exact path="/PropertyOwner/Gallery" component={Auth(Gallery, true)} />
          <Route exact path="/PropertyOwner/AllServiceProfessionalPage" component={Auth(VerificationBeforeDetails, true)} />

          <Route exact path="/PropertyOwner/Projects" component={Auth(Projects, null)} />
          <Route exact path="/PropertyOwner/ProjectsDetails/:ProjectID" component={Auth(ProjectDetails, null)} />
          <Route exact path="/PropertyOwner/TransactionPage" component={Auth(POTransactionPage, true)} />
          <Route exact path="/PropertyOwner/PostedPropertyDetails/:PostedPropertyId" component={Auth(PostedPropertyDetails, true)} />
          <Route exact path="/PropertyOwner/EditedPropertyDetails/:PostedPropertyId" component={Auth(EditedPropertyDetails, true)} />
          <Route exact path="/PropertyOwner/RenewalPropertyDetails/:PostedPropertyId" component={Auth(RenewedPropertyDetails, true)} />
          <Route exact path="/PropertyOwner/PostedPropertyDetails/Edit/:PostedPropertyId" component={Auth(EditPropertyDetails, true)} />
          <Route exact path="/PropertyOwner/PostedPropertyDetails/Renew/:PostedPropertyId" component={Auth(RenewPropertyDetails, true)} />
          <Route exact path="/PropertyOwner/Forgotpassword" component={Auth(PO_ForgotPassword, false)} />
          <Route exact path="/reset/:token" component={Auth(PO_ResetPassword, false)} />
          {/* Analytics */}
          <Route exact path="/PropertyOwner/Analytics" component={Auth(PO_Analytics, true)} />

          {/* SERVICE PROFESSIONAL */}
          <Route exact path="/ServiceProfessional/Home" component={CompanyAuth(Home, true)} />
          <Route exact path="/ServiceProfessional/PropertyRequirements" component={CompanyAuth(Property, true)} />
          <Route exact path="/ServiceProfessional/PropertyRequirementsDetails/:ProjectId" component={CompanyAuth(PropertyDetails, true)} />
          <Route exact path="/PropertyOwner/PropertyRequirementsDetails/:ProjectId" component={Auth(UploadedPropertyDetails, true)} />
          <Route exact path="/serviceprofessional/Login" component={CompanyAuth(SPLogin, false)} />
          <Route exact path="/serviceprofessional/CartPage" component={CompanyAuth(CartPage, true)} />
          <Route exact path="/ServiceProfessional/Pricing" component={CompanyAuth(Pricing, null)} />
          {/* <Route exact path="/ServiceProfessional/PostPricing" component={CompanyAuth(PostPricing, true)} /> */}
          {/* <Route exact path="/ServiceProfessional/Pricing" component={CompanyAuth(WebinarPricing, true)} /> */}
          <Route exact path="/ServiceProfessional/Pricing/Membership" component={CompanyAuth(MembershipPricing, null)} />
          <Route exact path="/ServiceProfessional/MyPurchasedLead" component={CompanyAuth(MyPurchasedLeadTable, null)} />
          {/* <Route exact path="/ServiceProfessional/MyTransactionsPlans" component={CompanyAuth(TransactionTable, true)} /> */}
          <Route exact path="/serviceprofessional/Register" component={CompanyAuth(SP_Register, false)} />

          {/* Gallery */}
          <Route exact path="/ServiceProfessional/Gallery" component={CompanyAuth(Gallery, true)} />
          {/* Profile */}
          <Route exact path="/ServiceProfessional/MyProfile" component={CompanyAuth(ProfilePage, true)} />
          <Route exact path="/ServiceProfessional/MyStats" component={CompanyAuth(MyStats, true)} />
          <Route exact path="/ServiceProfessional/Overview" component={CompanyAuth(SP_Profile, true)} />

          {/* New and Previous Project */}
          <Route exact path="/ServiceProfessional/UploadNewProject" component={CompanyAuth(SP_UploadNewProject, true)} />
          <Route exact path="/ServiceProfessional/Projects" component={CompanyAuth(Projects, null)} />
          <Route exact path="/ServiceProfessional/ProjectsDetails/:ProjectID" component={CompanyAuth(ProjectDetails, null)} />
          <Route exact path="/ServiceProfessional/AllServiceProfessionalPage" component={CompanyAuth(VerificationBeforeDetails, true)} />
          <Route exact path="/ServiceProfessional/Profile/OtherView/:profileID" component={CompanyAuth(VerifyProjectDetailsBeforeLogin, true)} />
          <Route exact path="/ServiceProfessional/PreviouslyUploadedProject/ProjectsDetails/:ProjectID" component={CompanyAuth(PreviouslyProjectDetailsPage, true)} />
          <Route exact path="/ServiceProfessional/PreviouslyUploadedProject/ProjectsDetails/EditProject/:ProjectID" component={CompanyAuth(EditPreviouslyUploadedProjectDetailedPage,true)} />
          <Route exact path="/ServiceProfessional/forgotpassword" component={CompanyAuth(SP_ForgotPassword, false)} />
          <Route exact path="/resetServiceProfessional/:token" component={CompanyAuth(SP_ResetPassword, false)} />

          {/* Analytics */}
          <Route exact path="/ServiceProfessional/Analytics" component={CompanyAuth(SP_Analytics, true)} />

          {/* About Us */}
          <Route exact path={"/AboutUs"} component={Auth(About, null)} />
          <Route exact path={"/PropertyOwner/AboutUs"} component={Auth(About, true)} />
          <Route exact path={"/ServiceProfessional/AboutUs"} component={CompanyAuth(About, true)} />
          {/* Contact Us */}
          <Route exact path={"/ContactUs"} component={Auth(ContactUs, null)} />
          <Route exact path={"/PropertyOwner/ContactUs"} component={Auth(ContactUs, true)} />
          <Route exact path={"/ServiceProfessional/ContactUs"} component={CompanyAuth(ContactUs, true)} />
          {/* Testimonials */}
          <Route exact path={"/Testimonials"} component={Auth(TestimonialsAndReviews, null)} />
          <Route exact path={"/PropertyOwner/Testimonials"} component={Auth(TestimonialsAndReviews, true)} />
          <Route exact path={"/ServiceProfessional/Testimonials"} component={CompanyAuth(TestimonialsAndReviews, true)} />
          {/* Refund Replacement & Cancellation Policies */}
          <Route exact path={"/RefundReplacement&Cancellation"} component={Auth(RefundReplacement, null)} />
          <Route exact path={"/PropertyOwner/RefundReplacement&Cancellation"} component={Auth(RefundReplacement, true)} />
          <Route exact path={"/ServiceProfessional/RefundReplacement&Cancellation"} component={CompanyAuth(RefundReplacement, true)} />
          {/* Community Guidelines */}
          <Route exact path={"/Communityguidelines"} component={Auth(CommunityGuidelines, null)} />
          <Route exact path={"/PropertyOwner/Communityguidelines"} component={Auth(CommunityGuidelines, true)} />
          <Route exact path={"/ServiceProfessional/Communityguidelines"} component={CompanyAuth(CommunityGuidelines, true)} />

          {/* Terms & Cnnditions */}
          <Route exact path={"/terms&conditions"} component={Auth(Terms_Conditions, null)} />
          <Route exact path={"/PropertyOwner/terms&conditions"} component={Auth(Terms_Conditions, true)} />
          <Route exact path={"/ServiceProfessional/terms&conditions"} component={CompanyAuth(Terms_Conditions, true)} />
       
          {/* Privacy Policy */}
          <Route exact path={"/PrivacyPolicy"} component={Auth(Privacy_Policy, null)} />
          <Route exact path={"/PropertyOwner/PrivacyPolicy"} component={Auth(Privacy_Policy, true)} />
          <Route exact path={"/ServiceProfessional/PrivacyPolicy"} component={CompanyAuth(Privacy_Policy, true)} />

              {/* Property Owner Rules */}
          <Route path={"/PORules"} component={Auth(PORules, null)}  />
          <Route path={"/PropertyOwner/PORules"} component={Auth(PORules, true)}  />
          <Route path={"/ServiceProfessional/PORules"} component={CompanyAuth(PORules, true)}  />
              {/* Service Professional Rules */}
          <Route path={"/SPRules"} component={Auth(SPRules, null)}  />
          <Route path={"/PropertyOwner/SPRules"} component={Auth(SPRules, true)}  />
          <Route path={"/ServiceProfessional/SPRules"} component={CompanyAuth(SPRules, true)}  />
              {/* Declaration Content */}
          <Route path={"/Commonpage/Declaration/:user"} component={Auth(Declarationcontent, false)}  />
          <Route path={"/PropertyOwner/Declaration/:user"} component={Auth(Declarationcontent, true)}  />
          <Route path={"/ServiceProfessional/Declaration/:user"} component={CompanyAuth(Declarationcontent, true)}  />


          {/* Free Resources */}
          <Route path={"/PropertyOwner/FreeResources"} component={Auth(Freeresources, true)}  />
          <Route path={"/ServiceProfessional/FreeResources"} component={CompanyAuth(Freeresources, true)}  />

          <Route path={"/ServiceProfessional/spsuccess"} component={CompanyAuth(SPSuccess, true)} />
          <Route path={"/success"} component={SuccessPage} />
          <Route path={"/refund"} component={RefundReplacement} />
          <Route path={"/comingsoon"} component={ComingSoon} />
          <Route path={"/porules"} component={PORules} />
          <Route path={"/sprules"} component={SPRules} />
          <Route path={"/signinreq"} component={SignInRequired} />
          <Route path={"/pagination"} component={Pagination} />

          {/* Without Auth */}
          <Route path={"/PropertyOwner/PostPropertyWithoutAuth"} component={Auth(PostPropertyWithoutAuth, null)} />
          <Route path={"/PropertyOwner/NewPropertyRequirement"} component={NewFormPropertyOwner} />
          <Route path={"/successfullySumitedDetails"} component={ThankYouForPODetails} />
          <Route path={"/PropertyOwner/ThankYouPage"} component={Auth(ThankYou, null)} />
          <Route exact path="/leadsrequirements" component={CompanyAuth(Propertywithoutauth, null)} />

          {/* Replacement Page For Admin */}
          <Route exact path="/aman&harpreetNH/adminReplacementPage" component={CompanyAuth(ReplacementPage, true)} />

          {/* Daily Update E-Mail Notification Unsubscription */}
          <Route exact path="/Unsubscribed_Email_Notification/:emailId" component={CompanyAuth(UnsubscribeEmailNotification, null)} />

          {/* Landing Page */}
          <Redirect exact to={"/"} component={CompanyAuth(Home, null)}  />
        </Switch>
      </div>

      {/* Footer */}
      <Footer />
    </Suspense>
  );
}

export default App;
