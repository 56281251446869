import React from 'react'
import './ThankYouForPODetails.css';

function ThankYouForPODetails() {
  return (
    <div className='thankyoupodetails-container'>
        <div className='thanksyoudetails-container-A'>
            <p>Thank You !!!</p>
        </div>
        <div className='thanksyoudetails-container-B'>
            <p>We'll get you the budget friendly Service Professional</p>
        </div>
    </div>
  )
}

export default ThankYouForPODetails