// src/components/StepTwo.js
import React, { useState } from 'react';
import './StepTwo.css';
import { useEffect } from 'react';

const StepTwo = ({ formData, handleChange, prevStep, nextStep }) => {

  const [showSelect, setshowSelect] = useState(false);

    function changeSelectSelection() {
      setshowSelect(true);
    }

    useEffect(() => {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    });

    const handleCheckboxChange = (event) => {
      // console.log(event.target.name, event.target.checked);
      
      if(event.target.name === "Full Home Interiors"){
        if(event.target.checked === true){
          formData.ResidentailRequirements.FullHomeInterior = true;
          formData.ResidentailRequirementsString = formData.ResidentailRequirementsString + "Full Home Interiors, ";
        }
        if(event.target.checked === false){
          formData.ResidentailRequirements.FullHomeInterior = false;
          const newItems = formData.ResidentailRequirementsString.replace("Full Home Interiors, ", "");
          formData.ResidentailRequirementsString = newItems;
        }
      }
      
      if(event.target.name === "Bathroom"){
        if(event.target.checked === true){
          formData.ResidentailRequirements.Bathroom = true;
          formData.ResidentailRequirementsString = formData.ResidentailRequirementsString + "Bathroom, ";
        }
        if(event.target.checked === false){
          formData.ResidentailRequirements.Bathroom = false;
          const newItems = formData.ResidentailRequirementsString.replace("Bathroom, ", "");
          formData.ResidentailRequirementsString = newItems;
        }
      }
      
      if(event.target.name === "Bedroom"){
        if(event.target.checked === true){
          formData.ResidentailRequirements.Bedroom = true;
          formData.ResidentailRequirementsString = formData.ResidentailRequirementsString + "Bedroom, ";
        }
        if(event.target.checked === false){
          formData.ResidentailRequirements.Bedroom = false;
          const newItems = formData.ResidentailRequirementsString.replace("Bedroom, ", "");
          formData.ResidentailRequirementsString = newItems;
        }
      }
      
      if(event.target.name === "Disabled / Handicap – Accessible Designs And Services"){
        if(event.target.checked === true){
          formData.ResidentailRequirements.DisabledHandicap = true;
          formData.ResidentailRequirementsString = formData.ResidentailRequirementsString + "Disabled / Handicap – Accessible Designs And Services, ";
        }
        if(event.target.checked === false){
          formData.ResidentailRequirements.DisabledHandicap = false;
          const newItems = formData.ResidentailRequirementsString.replace("Disabled / Handicap – Accessible Designs And Services, ", "");
          formData.ResidentailRequirementsString = newItems;
        }
      }
      
      if(event.target.name === "Entertainment Area"){
        if(event.target.checked === true){
          formData.ResidentailRequirements.EntertainmentArea = true;
          formData.ResidentailRequirementsString = formData.ResidentailRequirementsString + "Entertainment Area, ";
        }
        if(event.target.checked === false){
          formData.ResidentailRequirements.EntertainmentArea = false;
          const newItems = formData.ResidentailRequirementsString.replace("Entertainment Area, ", "");
          formData.ResidentailRequirementsString = newItems;
        }
      }
      
      if(event.target.name === "Fitness Area"){
        if(event.target.checked === true){
          formData.ResidentailRequirements.FitnessArea = true;
          formData.ResidentailRequirementsString = formData.ResidentailRequirementsString + "Fitness Area, ";
        }
        if(event.target.checked === false){
          formData.ResidentailRequirements.FitnessArea = false;
          const newItems = formData.ResidentailRequirementsString.replace("Fitness Area, ", "");
          formData.ResidentailRequirementsString = newItems;
        }
      }
      
      if(event.target.name === "Health And Safety"){
        if(event.target.checked === true){
          formData.ResidentailRequirements.HealthSafety = true;
          formData.ResidentailRequirementsString = formData.ResidentailRequirementsString + "Health And Safety, ";
        }
        if(event.target.checked === false){
          formData.ResidentailRequirements.HealthSafety = false;
          const newItems = formData.ResidentailRequirementsString.replace("Health And Safety, ", "");
          formData.ResidentailRequirementsString = newItems;
        }
      }
      
      if(event.target.name === "Kitchen And Dining"){
        if(event.target.checked === true){
          formData.ResidentailRequirements.KitchenDining = true;
          formData.ResidentailRequirementsString = formData.ResidentailRequirementsString + "Kitchen And Dining, ";
        }
        if(event.target.checked === false){
          formData.ResidentailRequirements.KitchenDining = false;
          const newItems = formData.ResidentailRequirementsString.replace("Kitchen And Dining, ", "");
          formData.ResidentailRequirementsString = newItems;
        }
      }
      
      if(event.target.name === "Living Room"){
        if(event.target.checked === true){
          formData.ResidentailRequirements.LivingRoom = true;
          formData.ResidentailRequirementsString = formData.ResidentailRequirementsString + "Living Room, ";
        }
        if(event.target.checked === false){
          formData.ResidentailRequirements.LivingRoom = false;
          const newItems = formData.ResidentailRequirementsString.replace("Living Room, ", "");
          formData.ResidentailRequirementsString = newItems;
        }
      }
      
      if(event.target.name === "Prayer Room"){
        if(event.target.checked === true){
        formData.ResidentailRequirements.PrayerRoom = true;
        formData.ResidentailRequirementsString = formData.ResidentailRequirementsString + "Prayer Room, ";
        }
        if(event.target.checked === false){
          formData.ResidentailRequirements.PrayerRoom = false;
          const newItems = formData.ResidentailRequirementsString.replace("Prayer Room, ", "");
          formData.ResidentailRequirementsString = newItems;
        }
      }
      
      if(event.target.name === "Outdoor"){
        if(event.target.checked === true){
          formData.ResidentailRequirements.Outdoor = true;
          formData.ResidentailRequirementsString = formData.ResidentailRequirementsString + "Outdoor, ";
        }
        if(event.target.checked === false){
          formData.ResidentailRequirements.Outdoor = false;
          const newItems = formData.ResidentailRequirementsString.replace("Outdoor, ", "");
          formData.ResidentailRequirementsString = newItems;
        }
      }
      
      if(event.target.name === "Passage Ways"){
        if(event.target.checked === true){
          formData.ResidentailRequirements.PassageWays = true;
          formData.ResidentailRequirementsString = formData.ResidentailRequirementsString + "Passage Ways, ";
        }
        if(event.target.checked === false){
          formData.ResidentailRequirements.PassageWays = false;
          const newItems = formData.ResidentailRequirementsString.replace("Passage Ways, ", "");
          formData.ResidentailRequirementsString = newItems;
        }
      }
      
      if(event.target.name === "Productivity Area"){
        if(event.target.checked === true){
          formData.ResidentailRequirements.ProductivityArea = true;
          formData.ResidentailRequirementsString = formData.ResidentailRequirementsString + "Productivity Area, ";
        }
        if(event.target.checked === false){
          formData.ResidentailRequirements.ProductivityArea = false;
          const newItems = formData.ResidentailRequirementsString.replace("Productivity Area, ", "");
          formData.ResidentailRequirementsString = newItems;
        }
      }
      
      if(event.target.name === "Security Systems"){
        if(event.target.checked === true){
          formData.ResidentailRequirements.SecuritySystems = true;
          formData.ResidentailRequirementsString = formData.ResidentailRequirementsString + "Security Systems, ";
        }
        if(event.target.checked === false){
          formData.ResidentailRequirements.SecuritySystems = false;
          const newItems = formData.ResidentailRequirementsString.replace("Security Systems, ", "");
          formData.ResidentailRequirementsString = newItems;
        }
      }
      
      if(event.target.name === "Utility"){
        if(event.target.checked === true){
          formData.ResidentailRequirements.Utility = true;
          formData.ResidentailRequirementsString = formData.ResidentailRequirementsString + "Utility, ";
        }
        if(event.target.checked === false){
          formData.ResidentailRequirements.Utility = false;
          const newItems = formData.ResidentailRequirementsString.replace("Utility, ", "");
          formData.ResidentailRequirementsString = newItems;
        }
      }
  }

  function checkForDisable() {
    if(formData.ServiceType === "Residential Interiors"){
      return !formData.ProjectArea.trim(); 
    } else {
      return !formData.ProjectArea.trim();
    }
  }

  return (
      <div className="stepTwo-main-container">
        <div className="stepTwo-div1-container">
          <div className='stepTwo-div1-header'>
            <p>{formData.ServiceType}</p>
          </div>
          <div className='stepTwo-div1-backButton-div'>
          <button className='stepTwo-div1-button-prev' onClick={prevStep}>Go Back</button>
          </div>
        </div>
        <div className="stepTwo-div2-container">
        {formData.ServiceType === "Residential Interiors" ? 
          <>
            <div className='stepTwo-flex-subBox1'>
              <p className='stepTwo-div2-subheading-style'>Residential BHK or Floor Plex in Square Feet <span style={{ color: 'red' }}>*</span></p>
              <select className='stepTwo-div2-select' name='ProjectArea' value={formData.ProjectArea} onChange={handleChange} onClick={changeSelectSelection}>
                <option value="" disabled={showSelect}>Select</option>
                <option value="0.5 BHK (0 to 500+ sq. ft.)">0.5 BHK (0 to 500+ sq. ft.)</option>
                <option value="1 BHK (0 to 1000+ sq. ft.)">1 BHK (0 to 1000+ sq. ft.)</option>
                <option value="1.5 BHK (0 to 1000+ sq. ft.)">1.5 BHK (0 to 1000+ sq. ft.)</option>
                <option value="2 BHK (0 to 1500+ sq. ft.)">2 BHK (0 to 1500+ sq. ft.)</option>
                <option value="2.5 BHK (0 to 1500+ sq. ft.)">2.5 BHK (0 to 1500+ sq. ft.)</option>
                <option value="3 BHK (0 to 2000+ sq. ft.)">3 BHK (0 to 2000+ sq. ft.)</option>
                <option value="3.5 BHK (0 to 2500+ sq. ft.)">3.5 BHK (0 to 2500+ sq. ft.)</option>
                <option value="4 BHK (0 to 3000+ sq. ft.)">4 BHK (0 to 3000+ sq. ft.)</option>
                <option value="4.5 BHK (0 to 3500 sq. ft.)">4.5 BHK (0 to 3500 sq. ft.)</option>
                <option value="5 BHK And Above (0 to 5000+ sq. ft.)">5 BHK And Above (0 to 5000+ sq. ft.)</option>
                <option value="Single-Level Floor Unit (Simplex) - 0 to 3000+ sq. ft.)">Single-Level Floor Unit (Simplex) - 0 to 3000+ sq. ft.)</option>
                <option value="Duplex (0 to 5000+ sq. ft.)">Duplex (0 to 5000+ sq. ft.)</option>
                <option value="Triplex (0 to 7000+ sq. ft.)">Triplex (0 to 7000+ sq. ft.)</option>
                <option value="Quadruplex or Fourplex (0 to 9000+ sq. ft.)">Quadruplex or Fourplex (0 to 9000+ sq. ft.)</option>
                <option value="Multi-Level Floor Units (0 to 11000+ sq. ft.)">Multi-Level Floor Units (0 to 11000+ sq. ft.)</option>
              </select>
            </div>
            <div className='stepTwo-div2-ri-checkboxes'>
              <div>
                <p className='stepTwo-div2-subheading-down-style'>Residential Interior Property Requirements<span style={{ color: 'red' }}>*</span></p>
              </div>
              <ul className='stepTwo-div2-checkbox-container' value={formData.ResidentailRequirements}>
                <li className='pr-checkbox-div' style={{ listStyleType: 'none' }}>
                  <label className='propertyRequirement-checkbox-label'>
                    <input
                      className='pr-checkbox-inputbox'
                      type="checkbox"
                      name="Full Home Interiors"
                      onChange={handleCheckboxChange}
                      defaultChecked={formData.ResidentailRequirements.FullHomeInterior}
                    />
                    Full Home Interiors
                  </label>
                </li>
                <li className='pr-checkbox-div' style={{ listStyleType: 'none' }}>
                  <label className='propertyRequirement-checkbox-label'>
                    <input
                      className='pr-checkbox-inputbox'
                      type="checkbox"
                      name="Bedroom"
                      onChange={handleCheckboxChange}
                      defaultChecked={formData.ResidentailRequirements.Bedroom}
                    />
                    Bedroom
                  </label>
                </li>
                <li className='pr-checkbox-div' style={{ listStyleType: 'none' }}>
                  <label className='propertyRequirement-checkbox-label'>
                    <input
                      className='pr-checkbox-inputbox'
                      type="checkbox"
                      name="Bathroom"
                      onChange={handleCheckboxChange}
                      defaultChecked={formData.ResidentailRequirements.Bathroom}
                    />
                    Bathroom
                  </label>
                </li>
                <li className='pr-checkbox-div' style={{ listStyleType: 'none' }}>
                  <label className='propertyRequirement-checkbox-label'>
                    <input
                      className='pr-checkbox-inputbox'
                      type="checkbox"
                      name="Disabled / Handicap – Accessible Designs And Services"
                      onChange={handleCheckboxChange}
                      defaultChecked={formData.ResidentailRequirements.DisabledHandicap}
                    />
                    Disabled / Handicap – Accessible Designs And Services
                  </label>
                </li>
                <li className='pr-checkbox-div' style={{ listStyleType: 'none' }}>
                  <label className='propertyRequirement-checkbox-label'>
                    <input
                      className='pr-checkbox-inputbox'
                      type="checkbox"
                      name="Entertainment Area"
                      onChange={handleCheckboxChange}
                      defaultChecked={formData.ResidentailRequirements.EntertainmentArea}
                    />
                    Entertainment Area
                  </label>
                </li>
                <li className='pr-checkbox-div' style={{ listStyleType: 'none' }}>
                  <label className='propertyRequirement-checkbox-label'>
                    <input
                      className='pr-checkbox-inputbox'
                      type="checkbox"
                      name="Fitness Area"
                      onChange={handleCheckboxChange}
                      defaultChecked={formData.ResidentailRequirements.FitnessArea}
                    />
                    Fitness Area
                  </label>
                </li>
                <li className='pr-checkbox-div' style={{ listStyleType: 'none' }}>
                  <label className='propertyRequirement-checkbox-label'>
                    <input
                      className='pr-checkbox-inputbox'
                      type="checkbox"
                      name="Health And Safety"
                      onChange={handleCheckboxChange}
                      defaultChecked={formData.ResidentailRequirements.HealthSafety}
                    />
                    Health And Safety
                  </label>
                </li>
                <li className='pr-checkbox-div' style={{ listStyleType: 'none' }}>
                  <label className='propertyRequirement-checkbox-label'>
                    <input
                      className='pr-checkbox-inputbox'
                      type="checkbox"
                      name="Kitchen And Dining"
                      onChange={handleCheckboxChange}
                      defaultChecked={formData.ResidentailRequirements.KitchenDining}
                    />
                    Kitchen And Dining
                  </label>
                </li>
                <li className='pr-checkbox-div' style={{ listStyleType: 'none' }}>
                  <label className='propertyRequirement-checkbox-label'>
                    <input
                      className='pr-checkbox-inputbox'
                      type="checkbox"
                      name="Living Room"
                      onChange={handleCheckboxChange}
                      defaultChecked={formData.ResidentailRequirements.LivingRoom}
                    />
                    Living Room
                  </label>
                </li>
                <li className='pr-checkbox-div' style={{ listStyleType: 'none' }}>
                  <label className='propertyRequirement-checkbox-label'>
                    <input
                      className='pr-checkbox-inputbox'
                      type="checkbox"
                      name="Prayer Room"
                      onChange={handleCheckboxChange}
                      defaultChecked={formData.ResidentailRequirements.PrayerRoom}
                    />
                    Prayer Room
                  </label>
                </li>
                <li className='pr-checkbox-div' style={{ listStyleType: 'none' }}>
                  <label className='propertyRequirement-checkbox-label'>
                    <input
                      className='pr-checkbox-inputbox'
                      type="checkbox"
                      name="Outdoor"
                      onChange={handleCheckboxChange}
                      defaultChecked={formData.ResidentailRequirements.Outdoor}
                    />
                    Outdoor
                  </label>
                </li>
                <li className='pr-checkbox-div' style={{ listStyleType: 'none' }}>
                  <label className='propertyRequirement-checkbox-label'>
                    <input
                      className='pr-checkbox-inputbox'
                      type="checkbox"
                      name="Passage Ways"
                      onChange={handleCheckboxChange}
                      defaultChecked={formData.ResidentailRequirements.PassageWays}
                    />
                    Passage Ways
                  </label>
                </li>
                <li className='pr-checkbox-div' style={{ listStyleType: 'none' }}>
                  <label className='propertyRequirement-checkbox-label'>
                    <input
                      className='pr-checkbox-inputbox'
                      type="checkbox"
                      name="Productivity Area"
                      onChange={handleCheckboxChange}
                      defaultChecked={formData.ResidentailRequirements.ProductivityArea}
                    />
                    Productivity Area
                  </label>
                </li>
                <li className='pr-checkbox-div' style={{ listStyleType: 'none' }}>
                  <label className='propertyRequirement-checkbox-label'>
                    <input
                      className='pr-checkbox-inputbox'
                      type="checkbox"
                      name="Security Systems"
                      onChange={handleCheckboxChange}
                      defaultChecked={formData.ResidentailRequirements.SecuritySystems}
                    />
                    Security Systems
                  </label>
                </li>
                <li className='pr-checkbox-div' style={{ listStyleType: 'none' }}>
                <label className='propertyRequirement-checkbox-label'>
                  <input
                    className='pr-checkbox-inputbox'
                    type="checkbox"
                    name="Utility"
                    onChange={handleCheckboxChange}
                    defaultChecked={formData.ResidentailRequirements.Utility}
                  />
                  Utility
                </label>
                </li>
              </ul>
            </div>
          </>
        : null}

          {formData.ServiceType === "Commercial Interiors" ?
            <div className='stepTwo-flex-subBox1'>
              <p className='stepTwo-div2-subheading-style'>Commercial Interior Property Requirements <span style={{ color: 'red' }}>*</span></p>
              <select className='stepTwo-div2-select' name='ProjectArea' value={formData.ProjectArea} onChange={handleChange} onClick={changeSelectSelection}>
                <option value="" disabled={showSelect}>Select</option>
                <option value="Retail Sector">Retail Sector</option>
                <option value="Hospitality Sector - (Accommodation, Food And Beverage, Travel And Tourism)">Hospitality Sector - (Accommodation, Food And Beverage, Travel And Tourism)</option>
                <option value="Healthcare Sector">Healthcare Sector</option>
                <option value="Office - Corporate And Tech Sector">Office - Corporate And Tech Sector</option>
                <option value="Industrial Sector">Industrial Sector</option>
                <option value="Educational Sector">Educational Sector</option>
                <option value="Financial Sector">Financial Sector</option>
                <option value="Media And Entertainment Sector">Media And Entertainment Sector</option>
                <option value="Research And Development Sector">Research And Development Sector</option>
                <option value="Transportation Sector">Transportation Sector</option>
              </select>
            </div>
          : null}

          {formData.ServiceType === "Residential Construction" || formData.ServiceType === "Commercial Construction" ? 
            <div className='stepTwo-flex-subBox1'>
              <p className='stepTwo-div2-subheading-style'>Construction Property Requirements <span style={{ color: 'red' }}>*</span></p>
              <select className='stepTwo-div2-select' name='ProjectArea' value={formData.ProjectArea} onChange={handleChange} onClick={changeSelectSelection}>
                <option value="" disabled={showSelect}>Select</option>
                <option value="Residential Sector (Apartment / Villa / Bungalow / Independent Home / Stand Alone Building / Penthouse)">Residential Sector (Apartment / Villa / Bungalow / Independent Home / Stand Alone Building / Penthouse)</option>
                <option value="Commercial Retail Sector">Commercial Retail Sector</option>
                <option value="Commercial Hospitality Sector - (Accommodation, Food And Beverage, Travel And Tourism)">Commercial Hospitality Sector - (Accommodation, Food And Beverage, Travel And Tourism)</option>
                <option value="Commercial Healthcare Sector">Commercial Healthcare Sector</option>
                <option value="Commercial Office - Corporate And Tech Sector">Commercial Office - Corporate And Tech Sector</option>
                <option value="Commercial Industrial Sector">Commercial Industrial Sector</option>
                <option value="Commercial Educational Sector">Commercial Educational Sector</option>
                <option value="Commercial Financial Sector">Commercial Financial Sector</option>
                <option value="Commercial Media And Entertainment Sector">Commercial Media And Entertainment Sector</option>
                <option value="Commercial Research And Development Sector">Commercial Research And Development Sector</option>
                <option value="Commercial Transportation Sector">Commercial Transportation Sector</option>
              </select>
            </div>
          : null}
          <div className='stepTwo-Next-button-container'>
            {checkForDisable() ? 
              <button
              className='stepTwo-button-next-disable' 
              disabled={true}
              >Next</button>
              :
              <button
              className='stepTwo-button-next-enable' 
              onClick={nextStep} 
              disabled={checkForDisable()} 
              >Next</button>
              }
            </div>
        </div>
      </div>
  );
};

export default StepTwo;
