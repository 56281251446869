// PRODUCT SERVER ROUTES
export const PRODUCT_SERVER = '/api/product'

//PROPERTY OWNER SERVER ROUTES
export const USER_SERVER = '/api/users';

//COMPANY SERVER ROUTES
export const COMPANY_SERVER = '/api/company';

//COMPANY SERVER ROUTES
export const SUBSCRIPTION_SERVER = '/api/subscription';

// EXECUTIVE SERVER ROUTES
export const EXECUTIVE_SERVER = '/api/executive';

// AGENT SERVER ROUTES
export const AGENT_SERVER = '/api/agents';

// TEAMLEADER SERVER ROUTES
export const TEAMLEADER_SERVER = '/api/teamleader';

// PROJECTS SERVER ROUTER
export const PROJECT_SERVER = '/api/project';

// GALLERY SERVER ROUTER
export const GALLERY_SERVER = '/api/gallery';

// COMPANY ANALYTICS
export const PERFORMANCE_ANALYTICS = '/api/performanceAnalytics';

// COMPANY ANALYTICS
export const CHATGPT_SERVER = '/api/chatgpt';